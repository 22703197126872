.what-we-do {
  width: 100%;
  gap: 0;
  background-image: url("../../Assets/curvy-background-v2-mobile.png");
  background-position: center;
  background-size: 120% 100%;
  background-repeat: no-repeat;
  color: white;
  margin: 3rem 0;
}

.what-we-do .h2 {
  width: 100%;
  margin-bottom: 0.6rem;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .what-we-do {
    background-image: url("../../Assets/curvy-background-v2-tablet.png");
  }
}

@media screen and (min-width: 1024px) {
  .what-we-do {
    background-image: url("../../Assets/curvy-background-v2.png");
  }
}

.what-we-do .background-texture-layer {
  width: 100%;

  background-image: url("../../Assets/background-texture-cropped-light.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.what-we-do .center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 800px;
  padding: 6rem 0 5rem;
}

@media screen and (min-width: 550px) {
  .what-we-do .center-container {
    width: 90%;
  }
}
@media screen and (min-width: 768px) {
  .what-we-do .grid > div {
    max-width: 90%;
  }
}
@media screen and (min-width: 1024px) {
  .what-we-do .grid > div {
    max-width: 85%;
  }
}
