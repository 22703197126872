.the-good-news {
  width: 100%;
}
.the-good-news .p--title {
}

.the-good-news .paragraph--closing {
  width: 90%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.the-good-news .title-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.the-good-news .mixed-elements-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  width: 100%;
  height: 100%;
}

.the-good-news .form-image-container > img {
  width: 100%;
  max-width: 180px;
  margin: 0 auto;
}

.the-good-news .good-news-image-container > img {
  width: 100%;
  max-width: 600px;
  margin: 30px auto 0;
}

.the-good-news .good-news-quote {
  font-weight: 400;
  font-style: normal;
}
