:root {
  --color-primary: #1e1f58;
  --color-primary-hover: hsl(239, 49%, 33%);
  --color-primary-placeholder: hsl(239, 49%, 43%);
  --color-primary-text: hsl(239, 49%, 23%);
  --color-primary-text-hover: hsl(239, 49%, 18%);
  /* --color-secondary: #2c6ca6; */
  /* --color-secondary-hover: hsl(209, 58%, 36%); */
  --color-secondary: #506ca8;
  --color-secondary-hover: hsl(221, 35%, 39%);
  --color-accent: #e6ae3e;
  --color-accent-hover: hsl(40, 77%, 50%);
  --color-three: #5e8daf;
  --color-four: #716f84;

  --color-light-grey: #fefefd;

  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  /* --toastify-color-success: #07bc0c; */
  --toastify-color-success: #8cb194 !important;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: var(--color-primary-text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 10px; */
}

.content--admin {
  display: flex;
  flex-direction: column;
  margin: 10px;
  gap: 20px;
}

.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.center-container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex--column {
  flex-direction: column;
}

.flex--justify {
  justify-content: center;
}

.flex--align {
  align-items: center;
}

/* .width80 {
  max-width: 80%;
}
.width90 {
  max-width: 80%;
} */

.section {
  /* padding: 50px 10px; */
  padding: 40px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}

@media screen and (min-width: 768px) {
  .section {
    /* padding: 65px 15px; */
    padding: 50px 15px;
    row-gap: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 60px 20px;
    row-gap: 40px;
  }
}

.block {
  width: 100%;
  /* padding: 20px 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  max-width: 1024px;
}

/* .auto--column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auto--column--xs {
  gap: 10px;
} */

@media screen and (min-width: 768px) {
  .block {
    /* padding: 30px 8px; */
    row-gap: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .block {
    /* padding: 30px 10px; */
    row-gap: 20px;
  }
}

.block--primary {
  background-color: var(--color-primary);
  color: white;
}

.break--xs {
  margin-bottom: 0.5rem;
}
.break--s {
  margin-bottom: 1rem;
}
.break--m {
  margin-bottom: 2rem;
}
.break--l {
  margin-bottom: 4rem;
}
.break--xl {
  margin-bottom: 5rem;
}

@media screen and (min-width: 550px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1350px) {
}

/* TEXT */

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

/* HEADINGS */

.circles-hero-banner-title {
  /* text-transform: uppercase; */
  text-transform: none;
  font-size: 3.9rem;
  line-height: 4.45rem;
  font-weight: 500;
  text-align: center;
  /* margin-bottom: 2rem; */
}

.h1 {
  /* color: var(--color-primary); */
  text-transform: uppercase;
  font-size: 2.9rem;
  line-height: 3.45rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  /* width: 100%; */
}

.h2 {
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 600;
  text-align: center;
}

.h3 {
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-weight: 400;
  /* margin-bottom: 1rem; */
}

@media screen and (min-width: 550px) {
  .circles-hero-banner-title {
    font-size: 4.4rem;
    line-height: 5rem;
  }
  .h1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
  .h2 {
    font-size: 2.7rem;
    line-height: 3.3rem;
  }
  .h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media screen and (min-width: 768px) {
  .circles-hero-banner-title {
    font-size: 5rem;
    line-height: 5.7rem;
  }
  .h1 {
    font-size: 3.5rem;
    line-height: 4.2rem;
  }
  .h2 {
    font-size: 3.1rem;
    line-height: 3.7rem;
  }
  .h3 {
    font-size: 2.3rem;
    line-height: 2.8rem;
  }
}

@media screen and (min-width: 1024px) {
  .circles-hero-banner-title {
    font-size: 5.6rem;
    line-height: 6.2rem;
  }
}

@media screen and (min-width: 1350px) {
  .circles-hero-banner-title {
    font-size: 6.2rem;
    line-height: 6.7rem;
  }
  .h1 {
    font-size: 4.8rem;
    line-height: 5.8rem;
  }
  .h2 {
    font-size: 3.3rem;
    line-height: 3.9rem;
  }
  .h3 {
    font-size: 2.6rem;
    line-height: 3.1rem;
  }
}

/* LISTS */

ul {
  padding-left: 1.8rem;
}

/* Lists */

.list {
  list-style: none;
  padding-left: 0;
}

.list--bullet {
  padding-left: 2rem;
}

.list--numbered li {
  margin: 0.8rem 0;
}

/* .list--bullet > li {
  font-size: 1.9rem;
  line-height: 2.7rem;
} */

.list--inline .list__item {
  display: inline-block;
  margin-right: 2rem;
}

/* PARAGRAPHS */

p,
li {
  text-align: left;
  font-weight: 300;
}

.p--centered--mobile {
  text-align: center;
}

.p--centered {
  text-align: center;
}

.p--left {
  text-align: left;
}

.p--bold {
  font-weight: 700;
}

.p--500 {
  font-weight: 500;
}

.p--block {
  max-width: 800px;
  margin: 0 auto;
}

.p--secondary {
  color: var(--color-secondary);
}
.p--accent {
  color: var(--color-accent);
}
.p--color-three {
  color: var(--color-three);
}
.p--color-four {
  color: var(--color-four);
}
.p--white {
  color: white;
}

.p--xs {
  font-size: 0.9rem;
  line-height: 1.6rem;
}

.p--sm {
  font-size: 1.2rem;
  line-height: 1.7rem;
}

.p--m {
  font-size: 1.4rem;
  line-height: 1.9rem;
}

.p--lg {
  font-size: 1.5rem;
  line-height: 2rem;
}

.p--xl {
  font-size: 1.6rem;
  line-height: 2.1rem;
}

.p--xxl {
  font-size: 1.8rem;
  line-height: 2.3rem;
}

.p--justified {
  text-align: justify;
}

.p--justified--centered {
  text-align: center;
}

.p--justified--left {
  text-align: left;
}

.p--left--mobile {
  text-align: left;
  width: 100%;
}

@media screen and (min-width: 550px) {
  .p--justified--centered {
    text-align: justify;
  }

  .p--left--mobile {
    text-align: center;
  }

  .p--justified--left {
    text-align: justify;
  }
}

@media screen and (min-width: 768px) {
  .p--centered--mobile {
    text-align: left;
  }

  .p--xs {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  .p--sm {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  .p--m {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .p--lg {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }

  .p--xl {
    font-size: 1.9rem;
    line-height: 2.4rem;
  }

  .p--xxl {
    font-size: 2.1rem;
    line-height: 2.6rem;
  }
}

@media screen and (min-width: 1024px) {
  .p--xs {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .p--sm {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .p--m {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .p--lg {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .p--xl {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .p--xxl {
    font-size: 2.2rem;
    line-height: 2.7rem;
  }
}

/* Links */

button {
  padding: 0;
  border-style: none;
  background-color: transparent;
  /* color: var(--color-primary); */
}

a {
  /* color: black; */
  text-decoration: none;
  font-weight: bold;
}

a:link {
  /* color: black; */
  text-decoration: none;
}

a:active {
  /* color: black; */
  text-decoration: none;
}

a:hover {
  color: var(--color-accent);
  text-decoration: none;
}

/* INPUTS */

.input--radio {
  width: 2rem !important;
  height: 2rem !important;
  max-width: none !important;
}

/* GRIDS */

.grid {
  display: grid;
  /* max-width: 100%; */
  gap: 15px;
}

.grid__image {
  justify-self: center;
  width: 80%;
  margin-bottom: 25px;
}

@media screen and (min-width: 768px) {
  /* this class only exists for tablets+ */
  .grid--1x2 {
    grid-template-columns: 1fr 1fr;
  }
  .grid--1x2--32 {
    grid-template-columns: 3fr 2fr;
  }
  .grid--1x2--25 {
    grid-template-columns: 2fr 5fr;
  }
  .grid--1x2--52 {
    grid-template-columns: 5fr 2fr;
  }
  .grid--1x2--23 {
    grid-template-columns: 2fr 3fr;
  }
  .grid--1x2--12 {
    grid-template-columns: 1fr 2fr;
  }
  .grid--1x2--21 {
    grid-template-columns: 2fr 1fr;
  }
  .grid--1x2--14 {
    grid-template-columns: 1fr 4fr;
  }
  .grid--1x2x3 {
    grid-template-columns: 1fr 1fr;
  }
  .grid--1x4 {
    grid-template-columns: 1fr 1fr;
  }
  .grid--1x4--wide {
    grid-template-columns: 1fr 1fr;
  }
  .grid__image {
    width: 100%;
    margin-left: 25px;
  }
}

@media screen and (min-width: 1024px) {
  /* this class only exists for tablets+ */
  .grid--1x2x3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid--1x3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid--1x4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .grid--1x4--wide {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1200px) {
  /* this class only exists for tablets+ */
  .grid--1x2-1200 {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1440px) {
  .grid--1x4--wide {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

/* Mobile Tablet desktop Blocks */

.mobile--block {
  display: block;
}

.mobile--inline {
  display: inline-block;
}

.tablet--block {
  display: none;
}

.tablet--inline {
  display: none;
}

.mobile-tablet--block {
  display: block;
}

.mobile-tablet--inline {
  display: inline-block;
}

.tablet-desktop--block {
  display: none;
}

.tablet-desktop--inline {
  display: none;
}

.desktop--block {
  display: none;
}

.desktop--inline {
  display: none;
}

@media screen and (min-width: 768px) {
  .mobile--block {
    display: none;
  }

  .mobile--inline {
    display: none;
  }

  .tablet--block {
    display: block;
  }

  .tablet--inline {
    display: inline-block;
  }

  .mobile-tablet--block {
    display: block;
  }

  .mobile-tablet--inline {
    display: inline-block;
  }

  .tablet-desktop--block {
    display: block;
  }

  .tablet-desktop--inline {
    display: inline-block;
  }

  .desktop--block {
    display: none;
  }

  .desktop--inline {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .mobile--block {
    display: none;
  }

  .mobile--inline {
    display: none;
  }

  .tablet--block {
    display: none;
  }

  .tablet--inline {
    display: none;
  }

  .mobile-tablet--block {
    display: none;
  }

  .mobile-tablet--inline {
    display: none;
  }

  .tablet-desktop--block {
    display: block;
  }

  .tablet-desktop--inline {
    display: inline-block;
  }

  .desktop--block {
    display: block;
  }

  .desktop--inline {
    display: inline-block;
  }
}

/* SPEECH BUBBLE TYPE 1 */

.text-center {
  text-align: center;
}

.cf {
  zoom: 1;
}

.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

/* body {
  background-color: #161719;
  font-family: "Roboto", sans-serif;
  color: #9fa2a7;
} */

.wrapper {
  width: 95%;
  max-width: 850px;
  margin: 30px auto 0;
  text-align: left;
  /*   display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5%; */
}

.wrapper h1 {
  margin-top: 50px;
  margin-bottom: 50px;
  text-transform: uppercase;
  font-size: 1.5em;
}

.wrapper .speechbubble {
  /* background-color: #26272b; */
  background-color: hsl(0, 0%, 93%);
  color: var(--color-primary);
  /* font-size: 0.8em; */
  /* line-height: 1.75; */
  /* padding: 15px 25px; */
  padding: 25px 25px 45px;
  /* margin-bottom: 75px; */
  margin-bottom: 45px;
  cursor: default;

  border-radius: 10px;
}

.wrapper .speechbubble:nth-child(2n) {
  border-left: 7px solid;
}

.wrapper .speechbubble--wide:nth-child(2n):after {
  content: "";
  margin-top: -30px;
  padding-top: 0px;
  position: relative;
  /* bottom: -45px; */
  /* bottom: -64px; */
  bottom: -74px;
  /* left: 20px; */
  left: 7%;
  border-width: 30px 0 0 30px;
  /* border-width: 40px 0 0 40px; */
  border-style: solid;
  border-color: hsl(0, 0%, 93%) transparent;
  display: block;
  width: 0;
}

.wrapper .speechbubble:nth-child(2n + 1) {
  border-right: 7px solid;
}

.wrapper .speechbubble--wide:nth-child(2n + 1):after {
  content: "";
  margin-top: -30px;
  padding-top: 0px;
  position: relative;
  bottom: -74px;
  /* bottom: -64px; */
  /* left: 350px; */
  left: 85%;
  border-width: 30px 30px 0 0;
  /* border-width: 40px 40px 0 0; */
  border-style: solid;
  /* border-color: #26272b transparent; */
  border-color: hsl(0, 0%, 93%) transparent;
  display: block;
  width: 0;
}

.wrapper .speechbubble:nth-child(4n + 1) {
  border-color: var(--color-secondary);
}

.wrapper .speechbubble:nth-child(4n + 2) {
  /* border-color: #01ad9b; */
  border-color: var(--color-accent);
}

.wrapper .speechbubble:nth-child(4n + 3) {
  /* border-color: #b388dd; */
  border-color: var(--color-three);
}

.wrapper .speechbubble:nth-child(4n + 4) {
  /* border-color: #ff8750; */
  border-color: var(--color-primary);
}

.wrapper .speechbubble p:before {
  content: "“";
  font-family: Georgia;
  font-size: 50px;
  line-height: 0;
  display: inline-block;
  display: -webkit-inline-box;
}

.wrapper .speechbubble .username {
  /* display: inline; */
  display: block;
  font-style: italic;
  float: right;
}

.wrapper .speechbubble .username:before {
  content: "- ";
}

/* MY ADDITIONS */
.wrapper .speechbubble p {
  display: inline;
}

.wrapper .speechbubble--large:nth-child(2n):after {
  bottom: -84px;
  border-width: 40px 0 0 40px;
}

.wrapper .speechbubble--large:nth-child(2n + 1):after {
  bottom: -84px;
  border-width: 40px 40px 0 0;
}

.wrapper .speechbubble--grid:nth-child(2n) {
  border-left: none;
  border-right: 7px solid;
}

.wrapper .speechbubble--grid:nth-child(2n + 1) {
  border-right: none;
  border-left: 7px solid;
}

.wrapper .speechbubble--grid:nth-child(2n):before {
  content: "";
  padding-top: 0px;
  position: relative;
  top: -55px;
  left: 7%;
  border-width: 0 0 30px 30px;
  border-style: solid;
  border-color: hsl(0, 0%, 93%) transparent;
  display: block;
  width: 0;
}

.wrapper .speechbubble--grid:nth-child(2n + 1):before {
  content: "";
  padding-top: 0px;
  position: relative;
  top: -54px;
  left: 85%;
  border-width: 0 30px 30px 0;
  border-style: solid;
  border-color: hsl(0, 0%, 93%) transparent;
  display: block;
  width: 0;
}

@media screen and (min-width: 768px) {
  .wrapper .speechbubble--grid:nth-child(2n):before {
    content: "";
    padding-top: 0px;
    position: relative;
    top: -54px;
    left: 7%;
    border-width: 0 0 30px 30px;
    border-style: solid;
    border-color: hsl(0, 0%, 93%) transparent;
    display: block;
    width: 0;
  }

  .wrapper .speechbubble--grid:nth-child(2n + 1):before {
    content: "";
    padding-top: 0px;
    position: relative;
    top: -54px;
    left: 7%;
    border-width: 0 0 30px 30px;
    border-style: solid;
    border-color: hsl(0, 0%, 93%) transparent;
    display: block;
    width: 0;
  }
}

.wrapper .speechbubble:nth-child(4n + 1) {
  border-color: var(--color-secondary);
}

.wrapper .speechbubble:nth-child(4n + 2) {
  /* border-color: #01ad9b; */
  border-color: var(--color-accent);
}

.wrapper .speechbubble:nth-child(4n + 3) {
  /* border-color: #b388dd; */
  border-color: var(--color-three);
}

.wrapper .speechbubble:nth-child(4n + 4) {
  /* border-color: #ff8750; */
  border-color: var(--color-primary);
}
