.the-cast-tool {
  width: 100%;
  background-image: url("../../Assets/background-texture-cropped-light.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
}
.the-cast-tool .h2 {
  margin-bottom: 0.6rem;
  text-align: left;
}
.the-cast-tool .grid {
  max-width: 680px;
  width: 95%;
  column-gap: 30px;
}
.the-cast-tool .paragraph--closing {
  width: 95%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.the-cast-tool .title-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (min-width: 550px) {
  .the-cast-tool .paragraph--closing {
    width: 90%;
  }
  .the-cast-tool .grid {
    width: 90%;
  }
}
