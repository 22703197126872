:root {
  --border-color: hsl(0, 0%, 85%);
}

.news-table {
  width: 100%;
  height: 100%;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  overflow-y: scroll;
  text-align: center;
  padding: 30px 0;
}

.news-table h1 {
  color: var(--color-primary);
  padding-bottom: 0px;
}

.news-table img {
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid black;
  max-width: 100%;
  aspect-ratio: 1;
  width: 120px;
  object-fit: cover;
  min-width: 120px;
  min-height: 120px;
}

.news-table .link {
  color: var(--color-accent);
  text-decoration: none;
  font-weight: bold;
}

.news-table .link:link {
  color: var(--color-accent);
  text-decoration: none;
}

.news-table .link:active {
  color: var(--color-accent);
  text-decoration: none;
}

.news-table .link:hover {
  color: var(--color-primary);
  text-decoration: none;
}

.table {
  color: var(--color-primary);
  max-width: 100%;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  border-collapse: collapse;
  border: 1px solid var(--color-primary);
}

.table-container {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.table td + td {
  border-left: 1px solid var(--color-primary);
}

@media screen and (min-width: 768px) {
  .table {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1024px) {
  .table {
    width: 100%;
    margin: 30px auto 1rem;
  }
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid var(--color-primary);
}

.table thead th {
  vertical-align: bottom;
  border: 1px solid #ffffff;
  /* border-bottom: 1px solid var(--color-primary); */
  background-color: var(--color-primary);
  color: white;
}

.table tbody {
  border-top: 1px solid var(--color-primary);
}
