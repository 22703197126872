.create-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.create-form {
  /* margin: 40px 0; */
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}

.create-form .create-form-overlay__content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 600px;
  flex-direction: column;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 1rem;
}

.create-form .buttons-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: row;
  align-items: center;
}

.create-form .input-container {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;
}

.create-form .input-container--formtype {
  gap: 10px;
  flex-direction: row;
  align-items: center;
}

.create-form .input-container--formtype input {
  margin: 0;
}

.create-form .input-container--number input {
  width: min-content;
}

.create-form .input-container > label {
  text-wrap: nowrap;
  /* align-self: center; */
  white-space: nowrap;
}

.create-form input {
  width: 100%;
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 0;
  border-bottom: 1px solid var(--color-primary);
  /* border-radius: 5px; */
  color: var(--color-primary);
  /* width: 100%;
  max-width: 300px;
  min-width: 200px; */
}

.create-form input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.create-form input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.create-form input::placeholder {
  color: var(--color-secondary);
}

.create-form input:focus {
  outline: none;
}
