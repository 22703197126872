.candidate-view-page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cast-form-background {
  width: 100%;
  height: 100%;
  /* background: linear-gradient(
    180deg,
    rgba(30, 31, 88, 1) 0%,
    rgba(80, 108, 168, 1) 50%,
    rgba(94, 141, 175, 1) 100%
  ); */
  /* slightly diagonal */
  /*  background: linear-gradient(
    164deg,
    rgba(30, 31, 88, 1) 0%,
    rgba(80, 108, 168, 1) 50%,
    rgba(94, 141, 175, 1) 100%
  ); */
  background: rgb(30, 31, 88);
  background: linear-gradient(
    164deg,
    rgba(30, 31, 88, 1) 0%,
    rgba(80, 108, 168, 1) 100%
  );
}

.cast-form-container {
  width: 95%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 0;
}

@media screen and (min-width: 550px) {
  .cast-form-container {
    padding: 3rem 0;
  }
}

@media screen and (min-width: 768px) {
  .cast-form-container {
    padding: 4rem 0;
  }
}

.cast-form-container .cast-form {
  width: 100%;
  padding: 30px 0;
}

.cast-form-container .cast-form--survey {
  padding: 0 0 30px;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 10px;
}

.cast-form-container .cast-form--survey .cast-form__question {
  margin: 0 auto;
}

.cast-form-container .cast-form--survey .cast-form__question__qtext b {
  font-weight: 500;
}

.cast-form-container .cast-form--survey .radio-options {
  max-width: 300px;
  padding-top: 5px;
}

.cast-form-container .cast-form--survey .cast-form__question__qtext__listitem {
  padding: 0;
}

.cast-form-container .cast-form--survey .cta {
  margin-top: 1rem;
}

.cast-form-container .cast-form__title-block {
  width: 100%;
  /* border-bottom: 2px solid var(--color-secondary); */
  background-color: #ffffff;
  padding: 1.5rem 1rem;
  border-radius: 10px;
}

@media screen and (min-width: 550px) {
  .cast-form-container .cast-form__title-block {
    /* padding: 2rem 1.5rem 0; */
    padding: 2rem 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  .cast-form-container .cast-form__title-block {
    /* padding: 2rem 1.5rem 0; */
    padding: 2rem 1.5rem;
  }
}

.cast-form-container .cast-form__title-block h2 {
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
  margin: 0 auto;
}
.cast-form-container .cast-form__title-block p {
  width: 100%;
  margin: 0 auto;
}

.cast-form-container .cast-form__question-container {
  /* border-bottom: 1px solid var(--color-secondary); */
  width: 100%;
  /* max-width: 800px; */
  /* border-radius: 10px; */
  /* margin-top: 5px; */
}

.cast-form-container .cast-form__question-container--subquestion {
  width: 97%;
  margin-left: 3%;
}

.cast-form-container .cast-form__question-wrapper {
  position: relative;
}

.cast-form-container .cast-form__question {
  /* border-bottom: 5px solid var(--color-accent); */
  width: 100%;
  margin: 0 auto;
  /* max-width: 800px; */
  /* padding: 1.5rem 2rem; */
  padding: 1rem;
  margin: 10px 0;
  background-color: #ffffff;
  border-radius: 10px;
}

@media screen and (min-width: 550px) {
  .cast-form-container .cast-form__question {
    padding: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  .cast-form-container .cast-form__question {
    /* padding: 2rem; */
  }
}

.cast-form-container .cast-form__question__horizontal-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: normal;
}

.cast-form-container .cast-form__question__horizontal-layout .radio-options {
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 900px) {
  .cast-form-container .cast-form__question__horizontal-layout {
    flex-direction: row;
    align-items: start;
  }
  .cast-form-container .cast-form__question__horizontal-layout .radio-options {
    margin-left: 3rem;
    margin-right: 1rem;
  }
}

.cast-form-container .cast-form__question-container--mergedquestion {
  margin-top: -25px;
}

.cast-form-container .cast-form__question--assessor {
  /* background-color: var(--color-secondary); */
  /* margin: -20px 0 0; */
}

.cast-form-container .cast-form__question__qtext {
  font-weight: 300;
  /* padding: 0.5rem 0; */
  padding: 0 0 0.5rem;
}

.cast-form-container .cast-form__question__qtext__listitem {
  list-style: none;
  padding: 0 0 1.5rem;
}

.cast-form-container select {
  color: var(--color-primary);
  border-color: var(--color-primary);
  border-radius: 3px;
  padding: 0.2rem 0.5rem 0.3rem;
}

.cast-form-container select:focus {
  outline: none;
}

/* RADIO OPTIONS FOR ROW */
.cast-form-container .cast-form__question--radio {
}

.cast-form-container .cast-form__question--radio .radio-options {
  display: flex;
  width: max-content;
  gap: 3rem;
  /* justify-content: space-around; */
  align-items: center;
}

.cast-form-container .cast-form__question--radio .radio-options--horizontal {
  gap: 1rem;
  width: 100%;
  justify-content: space-around;
  gap: none;
  align-items: flex-end;
}

/* @media screen and (min-width: 900px) {
  .cast-form-container .cast-form__question--radio .radio-options--b1a {
    gap: 2rem;
  }
}

@media screen and (min-width: 980px) {
  .cast-form-container .cast-form__question--radio .radio-options--b1a {
    gap: 3rem;
  }
} */

.cast-form-container .cast-form__question--radio .radio-options--yesno {
  justify-content: normal;
  gap: 3rem;
}

.cast-form-container .cast-form__question--radio .radio-options--assessor {
  display: flex;
  width: max-content;
  gap: 3rem;
  /* justify-content: space-around; */
  align-items: center;
}
.cast-form-container .cast-form__question--radio .radio-options > div {
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  align-items: center;
}

.cast-form-container
  .cast-form__question--radio
  .radio-options--assessor
  > div {
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}
.cast-form-container
  .cast-form__question--radio
  .radio-options--assessor
  .cast-form__question-assessment {
  max-width: min-content;
  word-wrap: none;
  white-space: nowrap;
}

.cast-form-container .cast-form__question--radio .radio-options > li {
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  align-items: center;
}

.cast-form-container .cast-form__question--radio label {
  width: fit-content;
  font-weight: 300;
  text-align: center;
}

.cast-form-container .cast-form__question--radio input {
  width: max-content;
  margin-top: 0.5rem;
}

/* RADIO OPTIONS FOR COLUMN */
.cast-form-container .cast-form__question--radio--column {
}

.cast-form-container .cast-form__question--radio--column .radio-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
}

.cast-form-container .cast-form__question--radio--column input,
.cast-form-container .cast-form__question--radio--column label {
  max-width: max-content;
  margin: 0.2rem;
}

.cast-form-container .cast-form__question--radio--column input {
  min-width: 2rem;
  min-height: 2rem;
}

.cast-form-container .cast-form__question--radio--column label {
  padding-left: 1rem;
  font-weight: 300;
}

.cast-form-container .cast-form__question--radio--column .radio-options > li {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
}

.cast-form-container
  .cast-form__question--radio--strongly
  .cast-form__question__horizontal-layout {
  /* display: flex; */
  /* justify-content: space-between; */
}

.cast-form-container .cast-form__question--radio--strongly .radio-options {
  width: 100%;
  max-width: 270px;
  gap: 1.5rem;
}

.cast-form-container .cast-form__question--number {
  width: auto;
}

.cast-form-container .cast-form__question--number::-webkit-inner-spin-button {
  opacity: 1;
}

.cast-form-container .cast-form__question__checkbox-list__question {
  display: flex;
  align-items: center;
}

.cast-form-container
  .cast-form__question__checkbox-list__question
  .cast-form__checkbox-input {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  max-width: 2rem;
  margin-bottom: 0;
}

.cast-form-container .cast-form__question__checkbox-list label {
  padding-left: 1rem;
}

.cast-form-container
  .cast-form__question__checkbox-list
  .cast-form__error-message-container {
  margin-top: 1rem;
}
/* 
.cast-form-container
  .cast-form__question__checkbox-list
  .cast-form__error-message-container {
  margin-top: 1rem;
} */

.cast-form-container label {
  display: block;
  margin: 0;
}

.cast-form-container input {
  margin: 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-secondary);
  margin-bottom: 1rem;
  font-weight: 300;
  color: var(--color-primary);
  font-family: inherit;
}

.cast-form textarea {
  margin: 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  font-family: inherit;
  font-weight: 300;
  min-height: 160px;
  color: var(--color-primary);
}

.cast-form textarea::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.cast-form textarea:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.cast-form textarea::placeholder {
  color: var(--color-secondary);
}

.cast-form textarea:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .cast-form textarea {
    min-height: 100px;
    padding: 1rem 1.5rem;
  }
}

.cast-form-container .cast-form__question-assessment-container {
  color: white;
  border-radius: 0 0 10px 10px;
  margin: -20px 0 0;
  background-color: var(--color-secondary);
  border: 1px solid #ffffff;
  padding: 1.5rem 2rem 0.5rem;
}
.cast-form-container .cast-form__question-assessment-container--supportive {
  /* background-color: blue; */
  /* background-color: var(--color-secondary); */
  /* background-color: grey; */
  border-top: 5px solid blue;
  color: white;
}
.cast-form-container .cast-form__question-assessment-container--unsupportive {
  /* background-color: grey; */
  border-top: 5px solid purple;
  color: white;
}
.cast-form-container .cast-form__question-assessment-container--demanding {
  /* background-color: grey; */
  border-top: 5px solid green;
  color: white;
}
.cast-form-container .cast-form__question-assessment-container--undemanding {
  /* background-color: grey; */
  border-top: 5px solid red;
  color: white;
}
.cast-form-container .cast-form__question-assessment-container--standard {
  /* background-color: var(--color-secondary); */
  color: white;
}

.cast-form-container .cast-form__question-assessment {
  /* width: 95%; */
  /* margin: 0 auto; */
  /* max-width: 800px; */
  /* padding: 0.5rem; */
  /* color: white; */
}

.cast-form-container .cast-form__question-assessment-container input {
  margin-bottom: 0.5rem;
}

.cast-form-container .question-score {
  padding: 0 1rem;
  display: inline-block;
  border-radius: 10%;
  background-color: white;
  color: var(--color-primary);
}

.cast-form-container .cast-form__question-assessment-container .score-guide {
  width: 100%;
  border-top: 1px solid #ffffff;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

.cast-form-container .error-block {
  width: 100%;
  color: #ffffff;
  background-color: var(--color-accent);
  margin: 10px 0;
  padding: 0.5rem 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cast-form-container .cast-form__error-message-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cast-form-container .cast-form__error-message {
  width: 100%;
  color: var(--color-accent);
  /* background-color: var(--color-accent); */
  /* color: white; */
  /* color: var(--color-accent); */
}

.cast-form-container .cast-form__error-message p {
  /* width: 95%; */
  width: 100%;
  /* margin: 0 auto; */
  /* max-width: 800px; */
  padding: 0.5rem 0;
}

.cast-form-container .cast-form__comments {
  border-top: 1px solid #ffffff;
  padding: 0.5rem 0;
  margin-top: 0.5rem;
}

.cast-form-container .cast-form__comments textarea {
  margin-top: 0.5rem;
}

/* CHECKED AND OPTIMISED UP TO HERE */

.cast-form .cast-form__grid {
  gap: 5%;
  margin-bottom: 50px;
  display: block;
}

@media screen and (min-width: 768px) {
  .cast-form .cast-form__grid {
    display: grid;
  }
}

.cast-form .serviceList {
  padding: 2.5rem 1rem 0.5rem;
}

.cast-form input::selection {
  background-color: var(--color-secondary);
  -webkit-background-clip: text;
}

.cast-form input:-webkit-autofill {
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: var(--color-primary); */
}

.cast-form input::placeholder {
  color: var(--color-primary);
}

.cast-form input:focus {
  outline: none;
}

/* Change the white to any color */
.footer-subscribe-form form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.cast-form .input__error-message {
  /* color: white; */
  color: var(--color-accent);
  /* font-size: 1.4rem; */
  /* border-radius: 15px; */
  /* margin-bottom: 0; */
}

.cast-form .cast-form__checkbox {
  display: inline;
  width: 15px;
  margin-bottom: 0;
}

.cast-form .cast-form__checkbox-label {
  display: inline;
  margin-left: 10px;
}

.cast-form .cta {
  margin: 30px auto 0;
}

.cast-form .recaptcha-container {
  margin: 20px 0;
}

/* COLLAPSIBLE COMMENTS */

.cast-form .react-icon__container {
  display: inline-block;
  border-radius: 50%;
  transform: rotate(0);
  transition: transform 0.3s;
  cursor: pointer;
}

.cast-form .react-icon__icon--chevronright {
  padding-left: 0px;
  vertical-align: bottom;
}
.cast-form .collapsible__chevron {
  margin-top: 1rem;
  margin-right: 1rem;
}
.cast-form .collapsible--expanded .react-icon__container {
  transform: rotate(90deg);
}
.cast-form .collapsible--expanded .collapsible__content {
  max-height: 100%;
  /* height: 100%; */
  opacity: 1;
}

.cast-form .cta {
  width: 160px;
  max-width: none;
}

@media screen and (min-width: 768px) {
  .cast-form .cta {
    width: 200px;
  }
}

.assessor-nav-panel {
  /* TODO: make this nav bar occupy the same space as the form window and for the form to have a bottom margin the same height as the nav bar */

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  padding: 1rem 1rem;
  text-align: center;
  border: 1px solid var(--color-primary);
  display: flex;
  gap: 8px 3px;
  flex-wrap: wrap;
}

.assessor-nav-panel a {
  color: var(--color-primary);
  text-decoration: none;
}

.file-links a:link {
  color: var(--color-primary);
}

.file-links a:active {
  color: var(--color-primary);
}

.file-links a:hover {
  color: var(--color-primary);
}

.assessor-nav-panel .assessor-nav-button {
  border: 1px solid var(--color-primary);
  padding: 0.2rem 0.6rem;
  border-radius: 3px;
}

.assessor-nav-panel .assessor-nav-button:hover {
  /* color: var(--color-secondary); */
  color: #ffffff;
  background-color: var(--color-secondary);
}

.assessor-nav-panel .assessor-nav-button__error {
  background-color: var(--color-accent);
}

.assessor-nav-panel .assessor-nav-button__error:hover {
  color: #ffffff;
  background-color: var(--color-secondary);
}

.assessor-nav-panel .assessor-nav-button__answered {
  background-color: var(--color-secondary);
}

.assessor-nav-panel .assessor-nav-button__new-section {
  margin-left: 4rem;
}

.cast-form .survey-button-container {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: max-content;
  margin: 0 auto;
  flex-wrap: wrap;
}

.cast-form-container .form-section-selection-spacer {
  padding-top: 110px;
}

@media screen and (min-width: 380px) {
  .cast-form-container .form-section-selection-spacer {
    padding-top: 80px;
  }
}

@media screen and (min-width: 550px) {
  .cast-form .survey-button-container {
    flex-direction: row;
    gap: 2rem;
  }
  .cast-form-container .form-section-selection-spacer {
    padding-top: 50px;
  }
}

@media screen and (min-width: 700px) {
  .cast-form-container .form-section-selection-spacer {
    padding-top: 20px;
  }
}
