.report-editor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px 0;
}

.report-editor .pdf-preview-container {
  width: 100%;
  max-width: 800px;
}

.report-editor .pdf-viewer {
  width: 95%;
  max-width: 800px;
  height: 1180px;
  margin-top: 2rem;
}

.report-editor .h3 {
  text-align: left;
}

.report-editor .cast-form__error-message-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.report-editor .input__error-message {
  color: var(--color-accent);
}
