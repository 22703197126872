.search-container {
  width: 100%;
  border-top: 1px solid var(--color-secondary);
  border-bottom: 1px solid var(--color-secondary);
  padding: 2rem 0;
}

.search-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  row-gap: 1rem;
  padding: 0 1rem;
}

@media screen and (min-width: 768px) {
  .search-container form {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
}

.search-container .search-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.search-container .category-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 290px;
}
.search-container .category-input-container > p {
  padding-top: 1rem;
}
.search-container .category-input-select-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 290px;
}

.search-input {
  width: 70%;
}

.nav-icon {
  margin: 1rem;
}

.search-container input {
  margin: 0;
  width: 290px;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 10px;
  color: var(--color-primary);
}

.search-container form input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.search-container form input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-primary);
  -webkit-background-clip: text;
}

.search-container form input::placeholder {
  color: var(--color-secondary);
}

.search-container form input:focus {
  outline: none;
}

/* Change the white to any color */
.search-container form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.search-container .input--select {
  margin: 0;
  width: 150px;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 10px;
  color: var(--color-primary);
  text-align: center;
  margin-left: 1rem;
}
