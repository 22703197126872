.header {
  width: 100%;
  background-color: white;
  flex-shrink: 0;
  border-bottom: 1px solid white;
  padding: 2rem;
}

.welcome {
  color: white;
  line-height: 1.1;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  padding-left: 1rem;
}

.app-title {
  justify-self: center;
  align-self: center;
  font-size: 2.5rem;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  color: white;
}

.navigation-bar {
  background-color: var(--color-primary);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.header__flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
