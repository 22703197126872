.assessment-hero-banner {
  width: 100%;
  background-image: url("../../Assets/background_circles_4.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-size: cover;
}

.assessment-hero-banner .grid {
  width: 100%;
  margin: 0 auto;
  max-width: 900px;
}

.assessment-hero-banner .h1 {
  text-transform: uppercase;
  text-align: center;
  color: white;
}

.assessment-hero-banner {
  row-gap: 10px;
}

@media screen and (min-width: 768px) {
  .assessment-hero-banner {
    row-gap: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .assessment-hero-banner {
    row-gap: 20px;
  }
}

.assessment-hero-banner .title-paragraph {
  text-align: center;
  max-width: 90%;
  padding-bottom: 1.5rem;
}

.assessment-hero-banner .form-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.assessment-hero-banner .form-image-container > img {
  width: 100%;
  max-width: 400px;
}
