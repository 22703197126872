.search-responses-container {
  width: 100%;
  border-top: 1px solid var(--color-secondary);
  border-bottom: 1px solid var(--color-secondary);
  padding: 2rem 0;
  margin-top: 2rem;
}

.search-responses-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 1rem;
  column-gap: 1.5rem;
  padding: 0 1rem;
}

.search-responses-container .category-input-select-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.search-responses-container .search-input-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* width: 100%; */
}

.search-responses-container .category-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (min-width: 768px) {
  .search-responses-container form {
    flex-direction: row;
    justify-content: flex-start;
  }
  .search-responses-container .category-input-select-container {
  }
}

.search-responses-container .category-input-container > p {
  padding-top: 1rem;
}

.search-responses-container .search-input {
  width: 70%;
}

.search-responses-container .nav-icon {
  margin: 1rem;
}

.search-responses-container input {
  margin: 0;
  width: 290px;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 10px;
  color: var(--color-primary);
}

.search-responses-container form input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.search-responses-container form input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-primary);
  -webkit-background-clip: text;
}

.search-responses-container form input::placeholder {
  color: var(--color-secondary);
}

.search-responses-container form input:focus {
  outline: none;
}

/* Change the white to any color */
.search-responses-container form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.search-responses-container .input--select {
  margin: 0;
  width: 150px;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 10px;
  color: var(--color-primary);
  margin-left: 1rem;
}
