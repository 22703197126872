.services .services-container {
  width: 100%;
}
.services h2,
.services h3 {
}

.services .p--left--mobile {
  width: 100%;
}
.services .cta {
  justify-self: end;
}
.services .in-person-assessment-quote {
  font-weight: 400;
  font-style: normal;
}
.services .bi-solid-quote-alt {
  font-size: 2rem;
}
.services ul {
  list-style: none;
  padding: 0 0 0 0.5rem;
}
.services .check-icon {
  margin: 0.5rem 1rem 0 0;
  font-size: 1.5rem;
  min-width: 2rem;
}
.services li {
  display: flex;
  align-items: start;
  justify-content: flex-start;
}
.services .options-container {
  width: 96vw;
  display: flex;
}
.services .options-container > div {
  display: inline-block;
  width: 98vw;
  padding: 7% 5% 7%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.services .option--1 {
  background-color: var(--color-secondary);
}
.services .option--2 {
  background-color: var(--color-three);
}
.services .option--3 {
  background-color: var(--color-accent);
}
.services .option--4 {
  background-color: var(--color-four);
}
.services .semicircles-container {
  width: 96vw;
  display: flex;
  margin: -2px 0 0;
}
.services .semicircles-container > div {
  display: inline-block;
  width: 48vw;
  border-radius: 0 0 24vw 24vw;
}
.services .semicircle--1 {
  background-color: var(--color-secondary);
  height: 24vw;
}
.services .semicircle--2 {
  background-color: var(--color-three);
  height: 36vw;
}
.services .semicircle--3 {
  background-color: var(--color-accent);
  height: 24vw;
}
.services .semicircle--4 {
  background-color: var(--color-four);
  height: 36vw;
}

.services .services--mobile {
  display: block;
}

.services .services--desktop {
  display: none;
}

.services .services-container--bottom {
  margin-top: 20px;
}

.services .block {
  width: 95%;
  margin-top: 2rem;
}

.services .block--intro {
  width: 95%;
}

.services .h2 {
  margin-bottom: 1.6rem;
}
.services .enquire-cta-container {
  width: 100%;
}
.services .enquire-cta-container p {
  margin-bottom: 2rem;
}
.services .enquire-cta-container .cta--wide {
  margin: 0 auto;
}

@media screen and (min-width: 550px) {
  .services .block {
    margin-top: 4rem;
  }
  .services .options-container > div {
    width: 92vw;
  }
}
@media screen and (min-width: 768px) {
  .services .block {
    width: 90%;
  }
  .services .block--intro {
    width: 90%;
  }
}

@media screen and (min-width: 678px) {
  .services .services--mobile {
    display: none;
  }

  .services .services--desktop {
    display: block;
  }

  .services .services .options-container {
    width: 96vw;
    display: flex;
  }
  .services .options-container > div {
    display: inline-block;
    width: 32vw;
    padding: 3% 1% 1%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
  }
  .services .semicircles-container {
    width: 96vw;
    display: flex;
  }
  .services .semicircles-container > div {
    display: inline-block;
    width: 32vw;
    border-radius: 0 0 16vw 16vw;
  }
  .services .semicircle--1 {
    height: 18vw;
  }
  .services .semicircle--2 {
    height: 24vw;
  }
  .services .semicircle--3 {
    height: 24vw;
  }
  .services .semicircle--4 {
    height: 18vw;
  }
}

@media screen and (min-width: 900px) {
  .services .options-container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
  }
  .services .options-container > div {
    display: inline-block;
    width: 32vw;
    padding: 3% 1% 1%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .services .semicircles-container {
    width: 100%;
    display: flex;
    max-width: 960px;
    margin: -2px auto 0;
  }
  .services .semicircles-container > div {
    display: inline-block;
    width: 32vw;
    border-radius: 0 0 16vw 16vw;
  }
  .services .semicircle--1 {
    height: 16vw;
  }
  .services .semicircle--2 {
    height: 24vw;
  }
  .services .semicircle--3 {
    height: 20vw;
  }
}
