.delete-subscriber .button--table {
  color: var(--color-accent);
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding: 0;
}

.delete-subscriber .button--table:hover {
  color: var(--color-primary);
}

.delete-subscriber .button--delete-subscriber:hover {
  background-color: var(--color-secondary);
}

.delete-subscriber .button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  max-width: 230px;
}

.delete-subscriber .button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.delete-subscriber .cta {
  min-width: 100px;
}
