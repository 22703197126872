.report-comments-form {
  background-color: #ffffff;
  width: 100%;
  max-width: 790px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.report-comments-form textarea {
  display: block;
  margin: 0.3rem 0 0;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  font-family: inherit;
  font-weight: 300;
  min-height: 160px;
  color: var(--color-primary);
}

.report-comments-form textarea::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.report-comments-form textarea:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.report-comments-form textarea::placeholder {
  color: var(--color-secondary);
}

.report-comments-form textarea:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .report-comments-form textarea {
    min-height: 100px;
    padding: 1rem 1.5rem;
  }
}

.report-comments-form input::selection {
  background-color: var(--color-secondary);
  -webkit-background-clip: text;
}

.report-comments-form input:-webkit-autofill {
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: var(--color-primary); */
}

.report-comments-form input::placeholder {
  color: var(--color-primary);
}

.report-comments-form input:focus {
  outline: none;
}

.report-comments-form .input-container {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.report-comments-form .cta {
  margin: 0 auto;
}

.report-comments-form .cta {
  width: 160px;
  max-width: none;
}

@media screen and (min-width: 768px) {
  .report-comments-form .cta {
    width: 200px;
  }
}

.report-comments-form .barchart-container {
  width: fit-content;
  margin: 0 auto;
}

.report-comments-form .scattergraph-container {
  width: fit-content;
  margin: 0 auto;
}

.report-comments-form .report-comments-form__error-message {
}

.report-comments-form .cast-form__question-container {
  /* border-bottom: 1px solid var(--color-secondary); */
  width: 100%;
  /* max-width: 800px; */
  /* border-radius: 10px; */
  /* margin-top: 5px; */
}

.report-comments-form .cast-form__question-container--subquestion {
  width: 97%;
  margin-left: 3%;
}

.report-comments-form .cast-form__question-wrapper {
  position: relative;
}

.report-comments-form .cast-form__question {
  /* border-bottom: 5px solid var(--color-accent); */
  width: 100%;
  margin: 0 auto;
  /* max-width: 800px; */
  /* padding: 1.5rem 2rem; */
  margin: 10px 0;
  background-color: #ffffff;
  border-radius: 10px;
}

.report-comments-form .cast-form__question__qtext {
  font-weight: 300;
  padding: 0.5rem 0;
}

.report-comments-form .cast-form__question__qtext__listitem {
  list-style: none;
  padding: 0 0 1.5rem;
}

.report-comments-form label {
  display: block;
  margin: 0;
}

.report-comments-form input {
  margin: 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-secondary);
  margin-bottom: 1rem;
  font-weight: 300;
  color: var(--color-primary);
  font-family: inherit;
}

.report-comments-form .cast-form__error-message {
  width: 100%;
  color: var(--color-accent);
}

.report-comments-form .cast-form__error-message p {
  width: 100%;
  padding: 0.5rem 0;
}
