.pdf-document {
  background-color: #ffffff;
  /* padding: 30px; */
}

.pdf-document .pdf-content {
  height: 90%;
  width: 90%;
  margin: 30px auto;
}

.pdf-document .barchart-container {
  max-width: 40%;
  margin: 0 auto;
}

.pdf-document .barchart-container > img {
  max-width: 100%;
}

.pdf-document .scattergraph-container {
  max-width: 60%;
  margin: 0 auto;
}

.pdf-document .scattergraph-container > img {
  width: 100%;
}
