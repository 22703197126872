.confirm-submission .button--table {
  color: var(--color-accent);
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding: 0;
  font-weight: 700;
}

.confirm-submission .button--table:hover {
  color: var(--color-primary);
}

.confirm-submission .button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  max-width: 230px;
}

.confirm-submission .button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.confirm-submission .cta {
  min-width: 100px;
  margin-top: 0;
  max-width: max-content;
}
