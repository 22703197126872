.comments-section {
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comments-section button {
  margin: 2rem 0;
}
