/* ================================================== */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 25px;
}

.login-elements {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-form label {
  display: block;
  margin: 0;
  margin-top: 2rem;
}

.login-form input {
  margin: 0;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  color: var(--color-primary);
}

.login-form input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.login-form input:-webkit-autofill {
  /* -webkit-background-clip: text; */
  -webkit-text-fill-color: var(--color-primary);
}

.login-form input::placeholder {
  color: gray;
}

.login-form input:focus {
  outline: none;
}

/* Change the white to any color */
.footer-subscribe-form form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.input__error-message {
  color: var(--color-secondary);
  font-size: 1.4rem;
  border-radius: 15px;
  margin-bottom: 0;
}

.login-form .login-form__checkbox {
  display: inline;
  width: 15px;
  margin-bottom: 0;
}

.login-form .login-form__checkbox-label {
  display: inline;
  margin-left: 10px;
}

.login-form .button {
  display: block;
  margin-top: 30px;
  margin-bottom: 50px;
}
