.assessment-procedure-section {
  width: 100%;
}

.assessment-procedure-section .grid {
  gap: 40px;
}
.assessment-procedure-section .grid ul {
  max-width: 680px;
  width: 98%;
  margin: 0 auto;
  padding-right: 0.5rem;
}
.assessment-procedure-section .grid li {
  /* text-align: left; */
}
.assessment-procedure-section .column-container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
}
.assessment-procedure-section .interview-image-container > img {
  margin: 0 auto;
  width: 100%;
  max-width: 340px;
}
.assessment-procedure-section .assessment-procedure-image-container > img {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}

.assessment-procedure-section .assessment-main-banner-container > img {
  margin: 0 auto;
  width: 100%;
  max-width: 900px;
  margin-top: 3rem;
}

@media screen and (min-width: 550px) {
  .assessment-procedure-section .grid ul {
    width: 90%;
  }
}
@media screen and (min-width: 768px) {
  .assessment-procedure-section .grid {
    gap: 0px;
  }

  .assessment-procedure-section .grid p {
    max-width: 90%;
    margin: 0 auto;
  }
  .assessment-procedure-section .grid ul {
    width: 90%;
    text-align: justify;
    max-width: none;
  }
  .assessment-procedure-section .grid .h3 {
    text-align: center;
  }
  .assessment-procedure-section .assessment-main-banner-container > img {
    margin-top: 1.5rem;
  }
}
