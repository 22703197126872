.home-page {
  width: 100%;
  height: fit-content;
  overflow-y: scroll;
}

.home-page__header {
  width: 100%;
  border-bottom: 1px solid var(--color-primary);
  position: fixed;
  z-index: 1;
  background-color: white;
}

.home-page__main {
  width: 100%;
  margin-top: 48px;
}

.web-share {
  background-color: var(--color-secondary);
  border-radius: 50%;
  color: white;
  width: 48px;
  height: 48px;
  cursor: pointer;

  position: fixed;
  bottom: 10px;
  right: 10px;
}

.web-share:hover {
  opacity: 0.6;
}

.web-share button {
  color: white;
  padding-left: 13px;
  padding-top: 11px;
  font-size: 25px;
}

.title--mobile {
  background-color: var(--color-light-grey);
  padding: 20px;
  padding-top: 0;
  padding-top: 120px;
}

.title--desktop {
  background-color: var(--color-light-grey);
  padding: 45px;
  justify-self: center;
}

.social-media-icons-container {
  text-align: right;
  background-color: var(--color-light-grey);
  align-self: center;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.social-media-icons-container .cta {
  overflow: hidden;
}

.home-page__header .top-bar-container {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .title--desktop {
    padding: 25px;
    min-width: 500px;
    align-self: center;
  }
}

@media screen and (min-width: 1024px) {
  .web-share {
    background-color: var(--color-secondary);
    border-radius: 50%;
    color: white;
    width: 60px;
    height: 60px;
    cursor: pointer;

    position: fixed;
    bottom: 70px;
    right: 50px;
  }
  .web-share button {
    color: white;
    padding-left: 18px;
    padding-top: 15px;
    font-size: 30px;
  }
}

.top-bar-container .logo {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-bar-container .logo a {
  font-size: 0;
  line-height: 0;
}

.top-bar-container .logo img {
  width: 100px;
}
