.the-right-people {
  width: 100%;
}
.the-right-people .p--title {
}
.the-right-people .grid {
  max-width: 760px;
}
.the-right-people .paragraph--closing {
  width: 95%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.the-right-people .title-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 95%;
}

.the-right-people .good-to-great-image-container > img {
  display: flex;
  margin: 0 auto;
  width: 90%;
  max-width: 200px;
}

.the-right-people .the-right-people-image-container > img {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

.the-right-people .the-right-people-image-container {
  padding: 2rem 0;
}

.the-right-people .focus-icon-container {
  display: flex;
  align-items: end;
}

.the-right-people .development-icon-container {
  display: flex;
  align-items: end;
}

.the-right-people .focus-icon-container > img {
  display: flex;
  align-items: end;
  margin: 0 auto;
  width: 30px;
  max-width: 30px;
  padding-bottom: 1rem;
}

.the-right-people .development-icon-container > img {
  display: flex;
  margin: 0 auto;
  width: 40px;
  max-width: 40px;
  padding-bottom: 1rem;
}

.the-right-people .character-icon-container > img {
  display: flex;
  margin: 0 auto;
  width: 60px;
  max-width: 60px;
  padding-bottom: 1rem;
}

@media screen and (min-width: 550px) {
  .the-right-people .paragraph--closing {
    width: 90%;
  }

  .the-right-people .title-block {
    width: 90%;
  }
}

@media screen and (min-width: 768px) {
  .the-right-people .focus-icon-container > img {
    width: 40px;
    max-width: 40px;
  }

  .the-right-people .development-icon-container > img {
    width: 50px;
    max-width: 50px;
  }

  .the-right-people .character-icon-container > img {
    width: 80px;
    max-width: 80px;
  }

  .the-right-people .the-right-people-image-container {
    padding: 3rem 0;
  }
}

.the-right-people .focus-icon-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
