.create-news {
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.create-news > div {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 15px;
  max-width: 700px;
}

.create-news > div > label {
  text-wrap: nowrap;
  align-self: center;
}

.create-news input {
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204 204 204);
  border-radius: 5px;
  color: var(--color-primary);
  width: 100%;
  max-width: 300px;
  min-width: 200px;
}

.create-news input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.create-news input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.create-news input::placeholder {
  color: var(--color-secondary);
}

.create-news input:focus {
  outline: none;
}
