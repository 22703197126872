.character-qualities h2,
.character-qualities h3 {
  margin-bottom: 1rem;
}

.character-qualities .p--intro {
  width: 95%;
  max-width: 700px;
  margin: 0 auto;
}
.character-qualities .numbers-image-container {
  width: 80%;
  max-width: 700px;
  margin: 0 auto;
}
.character-qualities .numbers-image-container img {
  width: 100%;
  max-width: 700px;
}
.character-qualities .separating-line {
  width: 50px;
  min-width: 50px;
  font-size: 0;
  line-height: 0;
  padding: 0;
  margin: 0;
  height: 1px;
  background-color: var(--color-primary);
  margin: 1rem auto 1rem 0;
}

.character-qualities .qualities-list {
  list-style: none;
}

.character-qualities .outcomes-list {
  list-style: none;
  padding: 0;
  width: 95%;
}

@media screen and (min-width: 550px) {
  .character-qualities .p--intro {
    width: 90%;
  }
  .character-qualities .outcomes-list {
    width: 90%;
  }
  .character-qualities .separating-line {
    margin: 1rem auto;
  }
}
