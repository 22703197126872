.who-we-are-section {
  width: 100%;
}
.who-we-are-section .text-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: left;
}
.who-we-are-section .title-container {
  display: flex;
  flex-direction: column;
  row-gap: 0;
  align-items: left;
}
.who-we-are-section .grid .h2 {
  text-align: center;
  padding-left: 0px;
}
.who-we-are-section .title-container--patrick {
  max-width: 520px;
  padding-left: 15px;
}
.who-we-are-section .title-container--areti {
  max-width: 520px;
}

.who-we-are-section .tick-list {
  list-style: none;
  padding: 0 0 0 1.5rem;
}
.who-we-are-section .tick-list > li {
  font-weight: 500;
}
.who-we-are-section .check-icon {
  margin: 0.5rem 1rem 0 0;
  font-size: 1.5rem;
  min-width: 2rem;
}

.who-we-are-section .closing-paragraph {
  width: 90%;
  max-width: 700px;
}

@media screen and (min-width: 768px) {
  .who-we-are-section .grid > div {
  }
  .who-we-are-section .grid p {
    max-width: 90%;
  }
  .who-we-are-section .grid ul {
    max-width: 640px;
  }

  .who-we-are-section .grid .h2 {
    text-align: left;
    padding-left: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .who-we-are-section .grid > div {
  }
}

.who-we-are-section .headshot-image-container > img {
  display: flex;
  margin: 0 auto;
  width: 90%;
  max-width: 320px;
}
