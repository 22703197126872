.cta {
  border-radius: 2rem;
  padding: 0.7rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: max-content;
  font-size: 1.3rem;
  font-weight: 600;
  box-shadow: none;
}

.cta:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
/* removes the blue otline on key press such as enter */
.cta:focus-visible {
  outline: none; /* Apply the blue outline only when focused via keyboard navigation */
}

.cta:fo .cta--header {
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: max-content;
  font-size: 1.1rem;
  font-weight: 600;
}
.cta--wide {
  width: 90%;
  max-width: none;
}

.cta--accent {
  background-color: var(--color-accent);
  color: white;
  border: 1px solid var(--color-accent);
}

.cta--accent:hover {
  background-color: var(--color-accent-hover);
  color: white;
}

.cta--accent--clicked {
  background-color: white;
  color: var(--color-accent-hover);
  border: 1px solid var(--color-accent);
}

.cta--primary {
  background-color: var(--color-primary);
  color: white;
  border: 1px solid var(--color-primary);
}

.cta--primary:hover {
  background-color: var(--color-primary-hover);
  border: 1px solid var(--color-primary-hover);
  color: white;
}

.cta--primary--clicked {
  background-color: var(--color-primary-hover);
  border: 1px solid var(--color-primary-hover);
  color: white;
}

.cta--secondary {
  background-color: var(--color-secondary);
  color: white;
  border: 1px solid var(--color-secondary);
}

.cta--secondary:hover {
  background-color: var(--color-accent);
  border: 1px solid var(--color-accent);
  color: white;
}

.cta--outline {
  background-color: #ffffff;
  border: 1px solid var(--color-secondary);
  color: var(--color-primary-text);
}

.cta--outline:hover {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  color: #ffffff;
}

.cta--outline--clicked {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  color: #ffffff;
}

.cta--disabled {
  background-color: grey;
  color: white;
}

.cta--submit {
  margin-top: 20px;
}

.cta--header {
  background-color: var(--color-accent);
  color: white;
  border: 1px solid var(--color-accent);
}

.cta--header:hover {
  background-color: var(--color-accent-hover);
  color: white;
}

@media screen and (min-width: 768px) {
  .cta {
    font-size: 1.4rem;
  }
  .cta--header {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .cta {
    font-size: 1.6rem;
  }
  .cta--header {
    font-size: 1.4rem;
  }
}
