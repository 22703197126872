.blog {
  gap: 20px;
}

.blog .block {
  width: 92%;
}

.blog .articles {
  justify-content: center;
  gap: 30px;
}

.blog .article {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  max-width: 700px;
}

@media screen and (min-width: 550px) {
  .blog .block {
    width: 95%;
  }
}

@media screen and (min-width: 768px) {
  .blog .article {
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    text-align: center;
    align-items: flex-start;
    justify-content: left;
    gap: 20px;
  }
  .blog .article .image-container {
    width: 30%;
  }
  .blog .article .text-container {
    width: 70%;
  }
}

.blog .article__image {
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  aspect-ratio: 1.5;
  object-fit: cover;
  max-height: 350px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.blog .article__image--small {
  border-radius: 10px;
  max-width: 100%;
  margin-bottom: 2rem;
  aspect-ratio: 1.5;
  object-fit: cover;
  max-height: 230px;
  box-shadow: 0 0 20px 10px #f3f3f3;
}

.blog .no-search-results {
  margin: 3rem auto;
}

.blog .intro {
  padding-top: 50px;
}

.blog .intro-description {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 90%;
}

.blog .articles-flex-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  justify-content: space-around;
  margin: 0 auto;
}

/* to modify the button style to sit on top of image */
.blog .read-more {
  margin-top: -3rem;
  margin-bottom: 2rem;
  font-size: 16px;
  line-height: 21px;
  width: 100%;
  max-width: none;
  border: none;
  box-shadow: 0 10px 10px -10px #f3f3f3;
  background-color: var(--color-secondary);
  padding: 1rem;
  color: white;
  border-radius: 0 0 3px 3px;
  max-width: 400px;
  display: block;
  position: relative;
}
.blog .read-more:hover {
  color: var(--color-accent);
}

@media screen and (min-width: 1024px) {
  .blog .articles-flex-container .article {
    max-width: 300px;
  }
}

.blog .buttons-container {
  display: flex;
  gap: 3rem;
}

.blog .buttons-container button {
  min-width: 150px;
}

/* NEW CLASSES TO OVERWRITE CURRENT */

.blog .articles {
  justify-content: center;
  margin-top: 20px;
}

.blog .article {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  text-align: center;
  align-items: center;
  /* margin: 2rem; */
}

.blog .article__image {
  border: 1px solid black;
  max-width: 100%;
  aspect-ratio: 1.5;
  object-fit: cover;
}

.blog .p--article {
  margin-bottom: 2rem;
  font-weight: 500;
}

.blog .article__link {
  color: var(--color-primary);
  text-decoration: none;
  text-transform: uppercase;
}

.blog .article__link:link {
  color: var(--color-primary);
  text-decoration: none;
}

.blog .article__link:active {
  color: var(--color-accent);
  text-decoration: none;
}

.blog .article__link:hover {
  color: var(--color-accent);
  text-decoration: none;
}

.blog .article__link:active {
  color: var(--color-accent);
  text-decoration: none;
}
