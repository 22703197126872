.tiny {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tiny > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tiny__display-content {
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  padding: 20px 30px;
}

.tiny__display-content p {
  word-break: break-all;
  /* word-break: break-word; */
}

.tiny__display .react-icon__container {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid var(--color-primary);
  transform: rotate(0);
  transition: transform 0.3s;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.tiny__display .react-icon__icon {
  /* at the moment there are no general classes to apply to the icon - all are style/skin */
}

.tiny__display .react-icon__icon--small {
  color: var(--color-primary);
  width: 3rem;
  height: 3rem;
}

.tiny__display .react-icon__icon--chevronright {
  padding-left: 0px;
}

/* Collapsibles */

.tiny__display .collapsible__header {
  display: flex;
  justify-content: space-between;
}

.tiny__display .collapsible__heading {
  margin-top: 0;
}

.tiny__display .collapsible__chevron {
  margin-top: 1rem;
  margin-right: 1rem;
}

.tiny__display .collapsible__content {
  max-height: 0;
  overflow: scroll;
  opacity: 0;
  transition: all 0.3s;
}

.tiny__display .collapsible--expanded .react-icon__container {
  transform: rotate(90deg);
}

.collapsible--expanded .collapsible__content {
  max-height: 100%;
  opacity: 1;
}

/* to add the same styles as the site (from news & articles) */
/* !important used here to allow copy and pasting without clearing text format */

.tiny__display-content {
  word-break: break-word;
  height: 100%;
  color: var(--color-primary-text);
}

.news-item img {
  /* padding: 2rem; */
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
  max-width: 100% !important;
}

.tiny__display-content h1 {
  color: white;
  text-transform: uppercase;
  font-size: 2.9rem;
  line-height: 3.45rem;
  font-weight: 600;
}

@media screen and (min-width: 550px) {
  .tiny__display-content h1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }
}

@media screen and (min-width: 768px) {
  .tiny__display-content h1 {
    font-size: 3.5rem;
    line-height: 4.2rem;
  }
}

@media screen and (min-width: 1350px) {
  .tiny__display-content h1 {
    font-size: 4.8rem;
    line-height: 5.8rem;
  }
}

.tiny__display-content h2 {
  font-size: 3.1rem;
  line-height: 3.7rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.tiny__display-content h3 {
  font-size: 2.3rem;
  line-height: 2.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.tiny__display-content ol {
  margin-bottom: 2rem;
}

.tiny__display-content ul {
  margin-bottom: 2rem;
}

.tiny__display-content p,
.tiny__display-content span {
  word-break: break-word;
  color: var(--color-primary-text) !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 1.9rem !important;
  line-height: 2.7rem !important;
}

/* to change anchor to match site colours */

.tiny__display-content a {
  color: var(--color-accent) !important;
  text-decoration: none;
  font-weight: bold;
}

.tiny__display-content a:link {
  color: var(--color-accent);
  text-decoration: none;
}

.tiny__display-content a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}

.tiny__display-content a:hover {
  color: var(--color-primary-text);
  text-decoration: none;
}

.tiny__display-content a:active {
  color: var(--color-primary-text);
  text-decoration: none;
}

/* for spans within anchors */

.tiny__display-content a span {
  color: var(--color-accent) !important;
  text-decoration: none;
  font-weight: bold;
}

.tiny__display-content a span:link {
  color: var(--color-accent) !important;
  text-decoration: none;
}

.tiny__display-content a span:active {
  color: var(--color-primary-text) !important;
  text-decoration: none;
}

.tiny__display-content a span:hover {
  color: var(--color-primary-text) !important;
  text-decoration: none;
}

.tiny__display-content a span:active {
  color: var(--color-primary-text) !important;
  text-decoration: none;
}

.tiny__display-content img {
  /* padding: 2rem; */
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
  max-width: 100% !important;
}

.tiny__display-content p {
  margin-bottom: 1.6rem !important;
}
