.blog-hero {
  background-image: url("../../../Assets/background_circles_4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 50px;
  padding: 40px 0;
}

.blog-hero .circles-hero-banner-title {
  color: white;
}

.blog-hero p,
.blog-hero label {
  color: white;
}

.blog-hero .input-container input {
  width: 90%;
  max-width: 500px;
}

.blog-hero form {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
}

.blog-hero input {
  border-radius: 10px;
}

.blog-hero .cta {
  width: 200px;
  min-width: 200px;
}

.blog-hero .p--subscribe {
  max-width: 80%;
}

.blog-hero .form-container {
  width: 100%;
}
.blog-hero .input-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.blog-hero .popup--subscribe {
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-hero .popup--subscribe .contact-form {
  width: 100%;
}

.blog-hero .popup--subscribe .contact-form > div {
  width: 100%;
}

.blog-hero .popup--subscribe .input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.blog-hero .popup--subscribe input {
  width: 100%;
  max-width: none;
}

.blog-hero .popup--subscribe p,
.blog-hero .popup--subscribe label {
  color: black;
}

.blog-hero .popup--subscribe .cta--cancel {
  margin: 10px 0 15px;
}
