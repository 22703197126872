
.pagination-container {
  display: flex;
  list-style-type: none;
  margin: 30px auto 30px;
  justify-content: center;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    // color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    // border-radius: 16px;
    border-radius: 16px;
    // line-height: 1.43;
    font-size: 16px;
    min-width: 32px;
    color: var(--color-primary);

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      // background-color: rgba(0, 0, 0, 0.04);
      background-color: var(--color-accent);
      opacity: 0.6;
      cursor: pointer;
    }

    &.selected {
      // background-color: rgba(0, 0, 0, 0.08);
      background-color: var(--color-accent);
      color: white;
      
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
       /*  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43); */
        border-right: 0.12em solid var(--color-primary);
        border-top: 0.12em solid var(--color-primary);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}