.add-news-form {
  font-size: 1.9rem;
  line-height: 2.7rem;
}

.add-news-form label {
  display: block;
  margin: 0;
  margin-top: 2rem;
}

.add-news-form .input--select {
  display: inline-block;
  margin-left: 2rem;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204 204 204);
  border-radius: 5px;
  color: var(--color-primary);
}

.add-news-form .label--select {
  display: inline-block;
}

.add-news-form input {
  margin: 0;
  font-size: 1.9rem;
  line-height: 2rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
}

.add-news-form input::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.add-news-form input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.add-news-form input::placeholder {
  color: var(--color-secondary);
}

.add-news-form input:focus {
  outline: none;
}

.add-news-form textarea {
  margin: 0;
  font-size: 1.9rem;
  line-height: 2rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  min-height: 100px;
  font-family: inherit;
}

.add-news-form textarea::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.add-news-form textarea:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.add-news-form textarea::placeholder {
  color: var(--color-secondary);
}

.add-news-form textarea:focus {
  outline: none;
}

.input__error-message {
  color: var(--color-secondary);
  font-size: 1.4rem;
  border-radius: 15px;
  margin-bottom: 0;
}

.add-news-form .add-news-form__checkbox {
  display: inline;
  width: 15px;
  margin-bottom: 0;
  margin-left: 10px;
}

.add-news-form .add-news-form__checkbox-label {
  margin-top: 25px;
}

.add-news-form .cta--save {
  display: block;
  margin-top: 20px;
  margin-bottom: 50px;
}

.upload-files--group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

@media screen and (min-width: 1124px) {
  .upload-files--group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}
