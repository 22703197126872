/* ================================================== */
.form-access-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 25px;
  background: rgb(30, 31, 88);
  background: linear-gradient(
    164deg,
    rgba(30, 31, 88, 1) 0%,
    rgba(80, 108, 168, 1) 100%
  );
}

.form-access-elements {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.form-access-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2rem;
}

.form-access-form .input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.form-access-form label {
  display: block;
  margin: 0;
  /* margin-top: 2rem; */
  margin-top: 0;
  color: #ffffff;
}

.form-access-form input {
  margin: 0;
  width: 100%;
  max-width: 300px;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  color: var(--color-primary);
  background-color: #ffffff;
  /* margin: 0.5rem 0; */
  margin: 0;
}

.form-access-form input::selection {
  background-color: var(--color-accent);
  /* -webkit-background-clip: text; */
}

.form-access-form input:-webkit-autofill {
  /* This was removed because it was removing the background color of the input to show the transparent background after deselcting after autofill */
  /* -webkit-background-clip: text; */
  -webkit-text-fill-color: var(--color-primary);
}

.form-access-form input::placeholder {
  color: gray;
}

.form-access-form input:focus {
  outline: none;
}

/* Change the white to any color */
.form-access-form .footer-subscribe-form form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
  /* TODO: fix the auto change of background color to primary */
  /* background-color: white !important; */
}

.form-access-form .input__error-message {
  color: var(--color-accent);
  font-size: 1.4rem;
  border-radius: 15px;
  margin-bottom: 0;
}

.form-access-form .form-access-form__checkbox {
  display: inline;
  width: 15px;
  margin-bottom: 0;
}

.form-access-form .form-access-form__checkbox-label {
  display: inline;
  margin-left: 10px;
}

.form-access-form .button {
  display: block;
  margin-top: 30px;
  margin-bottom: 50px;
}

.form-access-form .cta {
  display: block;
  /*   margin-top: 30px;
  margin-bottom: 50px; */
  margin: 0;
}
