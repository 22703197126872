.why-character {
  width: 100%;
}
.why-character .p--title {
}
.why-character .grid {
  max-width: 760px;
}
.why-character .paragraph--closing {
  width: 95%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.why-character .title-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.why-character .bi-solid-quote-alt {
  font-size: 2rem;
}

.why-character .character-quote {
  font-weight: 600;
  font-style: normal;
  color: var(--color-secondary);
}

.why-character .character-quotes-block {
  width: 95%;
}

@media screen and (min-width: 550px) {
  .why-character .paragraph--closing {
    width: 90%;
  }
  .why-character .character-quotes-block {
    width: 90%;
  }
}
