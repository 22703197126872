.admin-page {
  height: 100%;
}

.admin-page__content-nav {
  height: 100%;
  display: flex;
  min-height: 100vh;
  overflow: auto;
  /* max-width: calc(100% - 250px); */
  width: 100%;
  position: relative;
}

.admin__nav {
  height: 100%;
  background-color: rgb(244, 244, 244);
  width: 250px;
  text-align: center;
  padding: 30px 20px 20px;
  margin: 0;
}

.admin-page__content-nav .collapse-button {
  position: absolute;
  background-color: rgb(244, 244, 244);
  width: 35px;
  height: 35px;
  color: var(--color-primary);
}

.admin-page__content-nav .toggler {
  width: 35px;
  height: 35px;
  color: var(--color-primary);
  padding: 0.5rem;
}

.admin-page__content-nav .collapse-button--open {
  left: 215px;
}

.collapse-button {
  margin-bottom: 10px;
}

.admin__nav.collapsed {
  display: none;
}

.admin__content {
  padding: 25px;
  width: 100%;
  max-width: 100%;
}

.admin__content .welcome-message > p {
  padding-top: 20px;
}

.admin__nav button {
  font-size: 1.7rem;
}

.admin__nav button:hover {
  color: var(--color-accent);
}

.admin__nav li {
  padding: 20px 10px;
  border-bottom: 1px solid var(--color-primary);
}
