:root {
  --clr-grey-8: hsl(210, 31%, 80%);
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.endorsements-slider {
  /* giving an error when using the calc function */
  /* width: calc(100vw -5rem); */
  width: 97vw;
  margin: 5rem auto;
  max-width: 1000px;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .endorsements-slider {
    /* giving an error when using the calc function */
    /* width: calc(95vw -5rem); */
    width: 93vw;
  }
}
.endorsements-slider .title {
  text-align: center;
  margin-bottom: 2rem;
}

.endorsements-slider .h3 {
  padding-left: 0;
}

.endorsements-slider .title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.endorsements-slider .title span {
  /* font-size: 0.85em; */
  margin-right: 1rem;
  font-weight: 700;
}
.endorsements-slider .section-center {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
  /* height: 460px; */
  height: 580px;
}
@media screen and (min-width: 550px) {
  .endorsements-slider .section-center {
    height: 440px;
  }
}

@media screen and (min-width: 768px) {
  .endorsements-slider .section-center {
    /* height: 340px; */
    height: 420px;
  }
}

@media screen and (min-width: 1024px) {
  .endorsements-slider .section-center {
    /* height: 290px; */
    height: 420px;
  }
}

@media screen and (min-width: 1240px) {
  .endorsements-slider .section-center {
    height: 430px;
  }
}

.endorsements-slider .person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid var(--clr-grey-8);
  box-shadow: var(--dark-shadow);
}
.endorsements-slider article h4 {
  text-transform: uppercase;
  color: var(--color-primary-text);
  margin-bottom: 0.25rem;
  margin-left: 3rem;
  margin-right: 3rem;
}
.endorsements-slider .title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
}
.endorsements-slider .text {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
}
.endorsements-slider .icon {
  font-size: 3rem;
  margin-top: 1rem;
}
.endorsements-slider .prev,
.endorsements-slider .next {
  position: absolute;
  top: 30px;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 4rem;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transition);
  color: var(--color-primary);
}
.endorsements-slider .prev:hover,
.endorsements-slider .next:hover {
}
.endorsements-slider .prev {
  left: 0;
}
.endorsements-slider .next {
  right: 0;
}
@media (min-width: 800px) {
  .endorsements-slider .text {
    max-width: 80%;
  }
  .endorsements-slider .prev,
  .endorsements-slider .next {
    width: 4rem;
    height: 4rem;
    font-size: 4rem;
    top: 150px;
  }
}
.endorsements-slider article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: var(--transition);
}
.endorsements-slider article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
.endorsements-slider article.lastSlide {
  transform: translateX(-100%);
}
.endorsements-slider article.nextSlide {
  transform: translateX(100%);
}

.endorsements-slider .endorsement-image {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  height: 150px;
  min-height: 150px;
  background-color: white;
  border-radius: 50%;
  margin-bottom: 2rem;
  border: 1px solid var(--color-primary);
}
