.feedback-report-overview {
  width: 100%;
  max-width: 1024px;
}
.feedback-report-overview > div {
  width: 100%;
}
.feedback-report-overview h2,
.feedback-report-overview h3 {
  margin-bottom: 1rem;
}

.feedback-report-overview .auto--column--example {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
  max-width: 90%;
  margin: 0 auto;
}

.feedback-report-overview .mixed-elements-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.feedback-report-overview .icons-row-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}
.feedback-report-overview .icons-row-container > div {
  width: 90%;
  min-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feedback-report-overview .icons-row-container p {
  min-width: 180px;
}

.feedback-report-overview .graph-icon {
  width: 80px;
  padding: 5rem 0 1rem;
}
.feedback-report-overview .graph-icon--large {
  width: 130px;
  padding: 5rem 0 1rem;
}

@media screen and (min-width: 600px) {
  .feedback-report-overview .icons-row-container > div {
    width: 33%;
    min-width: 200px;
  }
  .feedback-report-overview .graph-icon {
    width: 90px;
    padding: 5rem 0 3rem;
  }
  .feedback-report-overview .graph-icon--large {
    width: 140px;
  }
}

.feedback-report-overview .form-image-container > img {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.feedback-report-overview .graph-image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
}

.feedback-report-overview .graph-image-container > img {
  width: 90%;
  max-width: 400px;
}

.feedback-report-overview .supportive-demanding-graph-container > img {
  width: 90%;
  max-width: 360px;
}

.feedback-report-overview .p--recommendation {
  max-width: 600px;
}

.feedback-report-overview .p--graphs {
  max-width: 600px;
  width: 100%;
}

.feedback-report-overview .quadrants-list {
  list-style: none;
}

.feedback-report-overview .separating-line {
  width: 50px;
  min-width: 50px;
  font-size: 0;
  line-height: 0;
  padding: 0;
  margin: 0;
  height: 1px;
  background-color: var(--color-primary);
  margin: 1rem auto;
}

.feedback-report-overview .recommendations {
  width: auto;
}

.feedback-report-overview .recommendations .grid {
  max-width: 760px;
}
.feedback-report-overview .recommendations .paragraph--closing {
  width: 90%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
}

.feedback-report-overview .recommendations .title-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feedback-report-overview .recommendations .recommendations-card {
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Collapsibles */

.feedback-report-overview .react-icon__container {
  display: inline-block;
  border-radius: 50%;
  transform: rotate(0);
  transition: transform 0.3s;
  cursor: pointer;
}

.feedback-report-overview .react-icon__icon--chevronright {
  padding-left: 0px;
}

.feedback-report-overview .collapsible__header {
  display: flex;
  justify-content: flex-start;
  /* padding-left: 10%; */
  cursor: pointer;
}

.feedback-report-overview .collapsible__heading {
  margin-top: 0;
}

.feedback-report-overview .collapsible__chevron {
  margin-top: 1rem;
  margin-right: 1rem;
}

.feedback-report-overview .collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.feedback-report-overview .collapsible--expanded .react-icon__container {
  transform: rotate(90deg);
}

.feedback-report-overview .collapsible--expanded .collapsible__content {
  max-height: 100%;
  /* height: 100%; */
  opacity: 1;
}

.feedback-report-overview .recommendations-list {
  margin: 0;
  padding: 0 0 0 1.6rem;
}
.feedback-report-overview .recommendations-list > li {
  padding: 0.2rem 0;
}

.feedback-report-overview .pdf-download-icon-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback-report-overview .pdf-download-icon-container > a > p {
  text-decoration: underline;
  color: var(--color-primary);
}

.feedback-report-overview .pdf-download-icon-container > a > p:hover {
  color: var(--color-accent);
}

.feedback-report-overview .pdf-download-icon-container > a > img {
  width: 100px;
  margin-bottom: 1rem;
}

.feedback-report-overview .graphs-container {
  max-width: 95%;
}
.feedback-report-overview .p--intro {
  max-width: 95%;
}
.feedback-report-overview .section-description {
  /* max-width: 600px; */
  text-align: left;
  width: 100%;
  /* max-width: 880px; */
}

@media screen and (min-width: 550px) {
  .feedback-report-overview .graphs-container {
    max-width: 95%;
  }
  .feedback-report-overview .p--intro {
    max-width: 90%;
  }
}
@media screen and (min-width: 768px) {
  .feedback-report-overview .graphs-container {
    max-width: 90%;
  }
}
@media screen and (min-width: 1024px) {
  .feedback-report-overview .graphs-container {
    max-width: 100%;
  }
  .feedback-report-overview .section-description {
    max-width: 600px;
    text-align: center;
  }
  .feedback-report-overview .section-description--2 {
    max-width: 700px;
  }
}
