.why-cast {
  width: 100%;
  gap: 0;
  background-image: url("../../Assets/curvy-background-v2-mobile.png");
  background-position: center;
  background-size: 120% 100%;
  background-repeat: no-repeat;
  color: white;
  margin: 3rem 0;
}

@media screen and (min-width: 768px) {
  .why-cast {
    background-image: url("../../Assets/curvy-background-v2-tablet.png");
  }
}

@media screen and (min-width: 1024px) {
  .why-cast {
    background-image: url("../../Assets/curvy-background-v2.png");
  }
}

.why-cast .background-texture-layer {
  width: 100%;
  background-image: url("../../Assets/background-texture-cropped-light.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.why-cast .block {
  width: 100%;
  max-width: none;
  padding: 8rem 0;
}

.why-cast .p--title {
}
.why-cast .grid {
  max-width: 680px;
  column-gap: 3rem;
  width: 95%;
}

.why-cast .paragraph--closing {
  width: 95%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.why-cast .title-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.why-cast .info-card {
  display: flex;
  align-items: center;
}

.why-cast .curvy-background-image-container > img {
  display: flex;
  width: 100%;
  max-width: 100%;
}

.why-cast .major-selling-point {
  font-size: 5.5rem;
  font-weight: bold;
}

.why-cast .separating-line {
  width: 50px;
  min-width: 50px;
  font-size: 0;
  line-height: 0;
  padding: 0;
  height: 1px;
  background-color: white;
  margin: 1rem auto 1rem 0;
}

.why-cast .outcomes-list {
  list-style: none;
  padding-left: 0;
  width: 95%;
  max-width: 680px;
}

@media screen and (min-width: 550px) {
  .why-cast .paragraph--closing {
    width: 90%;
  }
  .why-cast .grid {
    width: 90%;
  }
  .why-cast .outcomes-list {
    width: 90%;
  }
  .why-cast .separating-line {
    margin: 1rem auto;
  }
}
