/* Icons */

.icon {
  /*   width: 35px;
  height: 35px; */
  width: 25px;
  height: 25px;
}
.icon--white {
  color: white;
}

.nav {
  display: block;
  width: 100%;
  float: inline-start;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-light-gray);
  padding: 0;
}

.nav__bar {
  width: 100%;
  padding: 10px 10px 10px;
  background-color: white;
}

.nav__toggler-container {
  display: flex;
  justify-content: space-between;
}

.nav__toggler-container .logo {
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__toggler-container .logo a {
  font-size: 0;
  line-height: 0;
}

.nav__toggler-container .nav__icons--mobile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
}

.nav__media-icon {
  width: 25px;
  height: 25px;
  margin: 5px;
  color: var(--color-primary);
  cursor: pointer;
}

.nav__media-icon:hover {
  color: var(--color-secondary);
}

.nav__list {
  margin: 0;
  width: 100%;
  background-color: var(--color-primary);
  color: white;
  /* padding: 1.5rem 0; */
}

.collapsible--expanded .nav__list {
  padding: 1.5rem 0;
}

.nav__item {
  /* padding: 0.5rem 2rem; */
  padding: 0.5rem 2rem;
}

.nav__item button {
  color: white;
  transition: color 0.2s;
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  text-align: right;
  padding-left: 2%;
  cursor: pointer;
  white-space: nowrap;
}

.nav__item button:hover {
  color: var(--color-secondary);
}
.nav .collapsible--expanded .nav__toggler {
  opacity: 1;
  border-radius: 5px;
  box-shadow: 0 0 6px 3px #666;
}

.nav__brand {
  max-height: 120px;
  cursor: pointer;
}

.collapsible__content {
  max-height: 0;
  /* even with max-height: 0 we still see text, so this overflow must be hidden */
  overflow: hidden;
  opacity: 0;
  /* all used here to animate all properties */
  transition: all 0.5s;
}

.collapsible--expanded .collapsible__chevron {
  transform: rotate(0);
}

.collapsible--expanded .collapsible__content {
  /* max-height used instead of height so that image doesn't take up all of viewport */
  max-height: 100vh;
  opacity: 1;
}

.ig-feed--side {
  display: none;
}

@media screen and (min-width: 768px) {
}

/* TODO: some of these are the same as outside of the media query - delete duplicate styles */
@media screen and (min-width: 1024px) {
  .nav {
    display: block;
    float: left;
    overflow-y: hidden;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    background-color: white;

    position: relative;
    margin: auto 0;
  }

  .nav__bar {
    padding: 0;
  }

  .nav__list {
    margin: 0;
    width: 100%;
    background-color: white;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    max-width: 700px;
    padding: 0;
  }

  .collapsible--expanded .nav__list {
    padding: 0;
  }

  .nav__item {
    display: inline-block;
    padding: 0.5rem 0;
  }

  .nav__item button {
    color: var(--color-primary);
    text-transform: uppercase;
    font-weight: 700;
    transition: color 0.3s;
    text-decoration: none;
    text-align: center;
  }

  .nav__button button:hover {
    color: var(--color-secondary);
  }

  .nav__brand {
    max-width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    max-height: none;
  }

  .ig-feed--side {
    display: block;
  }
}
