.contact {
  width: 100%;
  padding: 4rem 0;
  border-bottom: 5px solid var(--color-secondary);
}

.contact .contact-form {
  background-color: white;
  padding: 2rem 3rem;
  border-radius: 3px;
}

.contact-form .contact-form__grid {
  gap: 5%;
  margin-bottom: 50px;
  display: block;
}

.contact-form label {
  display: block;
  margin: 0;
  margin-top: 2rem;
  padding-bottom: 0.5rem;
}

.contact-form input {
  margin: 0;
  width: 100%;
  max-width: 90%;
  padding: 0.8rem 1rem;
  border: none;
  border-bottom: 1px solid rgb(204, 204, 204);
}

@media screen and (min-width: 768px) {
  .contact-form .contact-form__grid {
    display: grid;
  }
  .contact-form input {
    padding: 1rem 1.5rem;
  }
}

.contact-form .serviceList {
  padding: 2.5rem 1rem 0.5rem;
}

.contact-form input::selection {
  background-color: var(--color-secondary);
  -webkit-background-clip: text;
}

.contact-form input::placeholder {
  color: var(--color-primary);
}

.contact-form input:focus {
  outline: none;
}

/* Change the white to any color */
.footer-subscribe-form form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.contact-form textarea {
  margin: 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  font-family: inherit;
  min-height: 160px;
}

@media screen and (min-width: 768px) {
  .contact-form textarea {
    min-height: 100px;
    padding: 1rem 1.5rem;
  }
}

.contact-form textarea::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.contact-form textarea:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.contact-form textarea::placeholder {
  color: var(--color-secondary);
}

.contact-form textarea:focus {
  outline: none;
}

.input__error-message {
  color: var(--color-secondary);
  font-size: 1.4rem;
  border-radius: 15px;
  margin-bottom: 0;
}

.contact-form .contact-form__checkbox {
  display: inline;
  width: 15px;
  margin-bottom: 0;
}

.contact-form .contact-form__checkbox-label {
  display: inline;
  margin-left: 10px;
}

.contact-form .button {
  display: block;
  margin-top: 30px;
  margin-bottom: 50px;
}

.contact-form .recaptcha-container {
  margin: 20px 0;
}

.contact-form .subscribe {
  padding-top: 30px;
}
