.form-section-selector {
  position: fixed;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  background-color: #f8f9fa;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  justify-content: center;
}

.form-section-button {
  background-color: var(--color-secondary);
  color: white;
  border: none;
  padding: 8px 10px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
}

.form-section-button:hover {
  background-color: var(--color-primary);
}
