.what-is-cast-for .cast-purposes h2 {
  margin-bottom: 2rem;
}
.what-is-cast-for h3 {
  text-align: left;
}
.what-is-cast-for .people-image-container {
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
}
.what-is-cast-for .people-image-container img {
  width: 100%;
  max-width: 700px;
}
.what-is-cast-for .team-image-container {
  width: 70%;
  max-width: 700px;
  margin: 20px auto;
}
.what-is-cast-for .team-image-container img {
  width: 100%;
  max-width: 700px;
}
.what-is-cast-for .paragraph--closing {
  width: 95%;
  max-width: 660px;
}
.what-is-cast-for .grid {
  max-width: 1024px;
  gap: 40px;
  align-items: center;
  width: 95%;
}

.what-is-cast-for .grid > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.what-is-cast-for ul {
  list-style: none;
  padding: 0 0 0 0.5rem;
}
.what-is-cast-for .check-icon {
  margin: 0.5rem 1rem 0 0;
  font-size: 1.5rem;
  min-width: 2rem;
}
.what-is-cast-for li {
  display: flex;
  align-items: start;
  justify-content: flex-start;
}
.what-is-cast-for .cast-purposes {
  max-width: 800px;
  width: 95%;
}
@media screen and (min-width: 550px) {
  .what-is-cast-for .cast-purposes {
    width: 90%;
  }
  .what-is-cast-for .paragraph--closing {
    width: 90%;
  }
}
