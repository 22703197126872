.landing-title {
  padding: 80px 20px;
}

.landing-title .grid-cell {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.landing-title .grid-cell h1,
.landing-title .grid-cell p,
.landing-title .grid-cell .cta {
  margin-top: 20px;
}

.landing-title .grid-cell img {
  width: 100%;
}

.landing-title .grid-cell--left img {
  max-width: 80%;
}

.landing-title .grid-cell--left {
  background-image: url("../../Assets/circlesBackground1.png");
  background-repeat: no-repeat;

  background-size: contain;
}

.landing-title .h1 {
  text-transform: uppercase;
  text-align: left;
  font-size: 30px;
}

.landing-title .title-paragraph {
  text-align: left;
  font-size: 18px;
  max-width: 90%;
  padding-bottom: 1.5rem;
}
