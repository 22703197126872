.levels-of-responsibility {
  width: 100%;
  gap: 0;
  background-image: url("../../Assets/curvy-background-v2-mobile.png");
  background-position: center;
  background-size: 120% 100%;
  background-repeat: no-repeat;
  color: white;
  margin: 3rem 0;
}

@media screen and (min-width: 768px) {
  .levels-of-responsibility {
    background-image: url("../../Assets/curvy-background-v2-tablet.png");
  }
}

@media screen and (min-width: 1024px) {
  .levels-of-responsibility {
    background-image: url("../../Assets/curvy-background-v2.png");
  }
}

.levels-of-responsibility .h2 {
  text-align: left;
}

.levels-of-responsibility .background-texture-layer {
  width: 100%;
  background-image: url("../../Assets/background-texture-cropped-light.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.levels-of-responsibility .block {
  padding: 8rem 0;
}

.levels-of-responsibility .p--title {
}
.levels-of-responsibility .grid {
  max-width: 680px;
  justify-content: left;
  width: 90%;
}
.levels-of-responsibility .paragraph--closing {
  width: 90%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.levels-of-responsibility .mixed-elements-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.levels-of-responsibility .levels-of-responsibility-container > img {
  width: 80%;
  max-width: 400px;
  align-self: flex-start;
}

@media screen and (min-width: 550px) {
  .levels-of-responsibility .grid {
    width: 80%;
  }
  .levels-of-responsibility .paragraph--closing {
    width: 80%;
  }
}
@media screen and (min-width: 768px) {
  .levels-of-responsibility .grid {
    width: 70%;
  }
  .levels-of-responsibility .paragraph--closing {
    width: 70%;
  }
}
