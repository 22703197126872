.emailSubscriber .copy-icon {
  font-size: 50px;
  border-radius: 50%;
  padding: 1rem;
  background-color: var(--color-secondary);
  color: white;
}

.emailSubscriber .copy-icon:hover {
  background-color: var(--color-primary);
}

.emailSubscriber {
  font-size: 1.9rem;
  line-height: 2.7rem;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.emailSubscriber label {
  display: block;
  margin: 0;
  margin-top: 2rem;
  padding-bottom: 0.5rem;
}

.emailSubscriber input {
  margin: 0;
  width: 100%;
  max-width: 90%;
  padding: 0.8rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
}

@media screen and (min-width: 768px) {
  .emailSubscriber input {
    padding: 1rem 1.5rem;
  }
}

.emailSubscriber .serviceList {
  padding: 2.5rem 1rem 0.5rem;
}

.emailSubscriber input::selection {
  background-color: var(--color-secondary);
  -webkit-background-clip: text;
}

.emailSubscriber input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.emailSubscriber input::placeholder {
  color: var(--color-primary);
}

.emailSubscriber input:focus {
  outline: none;
}

/* Change the white to any color */
.footer-subscribe-form form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.emailSubscriber textarea {
  margin: 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  font-family: inherit;
  min-height: 160px;
}

@media screen and (min-width: 768px) {
  .emailSubscriber textarea {
    min-height: 100px;
    padding: 1rem 1.5rem;
  }
}

.emailSubscriber textarea::selection {
  background-color: var(--color-accent);
  -webkit-background-clip: text;
}

.emailSubscriber textarea:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
}

.emailSubscriber textarea::placeholder {
  color: var(--color-secondary);
}

.emailSubscriber textarea:focus {
  outline: none;
}

.input__error-message {
  color: var(--color-secondary);
  font-size: 1.4rem;
  border-radius: 15px;
  margin-bottom: 0;
}

.emailSubscriber .emailSubscriber__checkbox {
  display: inline;
  width: 15px;
  margin-bottom: 0;
}

.emailSubscriber .emailSubscriber__checkbox-label {
  display: inline;
  margin-left: 10px;
}

.emailSubscriber .button {
  display: block;
  margin-top: 30px;
  margin-bottom: 50px;
}

.emailSubscriber .recaptcha-container {
  margin: 20px 0;
}

.emailSubscriber .subscribe {
  padding-top: 30px;
}
