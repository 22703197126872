.footer {
  background-color: var(--color-primary);
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.footer__link {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
}

.footer__link:link {
  color: white;
  text-decoration: none;
}

.footer__link:active {
  color: white;
  text-decoration: none;
}

.footer__link:hover {
  color: var(--color-accent);
  text-decoration: none;
}

.footer__link:active {
  color: white;
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .footer p {
    margin: 0;
    text-align: right;
  }
}
